<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
                    <vs-tab label="Camera" icon-pack="feather" icon="icon-camera">
                        <div class="tab-text">
                            <div id="branch-tab-info">
                                <!-- Content Row -->
                                <div class="vx-row">
                                    <div class="vx-col md:w-1/3 w-full">
                                        <label class="vs-input--label">{{ $t('root.organization') }}</label>
                                        <v-select class="" v-model="branchModel" :disabled="isUpdate" :clearable="false"
                                                  :options="branchOptions"
                                                  data-vv-validate-on="blur" v-validate="'required'" name="branch">
                                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                                        </v-select>
                                    </div>
                                    <div class="vx-col md:w-1/3 w-full">
                                        <vs-input :disabled="isUpdate" class="w-full mb-4"
                                                  :label="$t('root.cameraName')"
                                                  v-model="cameraInfo.cameraName" v-validate="'required'"
                                                  name="camera_name"/>
                                    </div>
                                    <div class="vx-col md:w-1/3 w-full">
                                        <vs-checkbox style="padding-top: 20px" v-model="cameraInfo.cameraStatus"
                                                     false-value="0" true-value="1">
                                            {{$t('root.activeStatus')}}
                                        </vs-checkbox>
                                    </div>
                                </div>

                                <div class="vx-row">
                                    <div class="vx-col md:w-1/3 w-full">
                                        <label class="vs-input--label">{{$t('root.cameraBrand')}}</label>
                                        <v-select :disabled="isUpdate" v-model="manufacture" :clearable="false"
                                                  :options="manufactureOptions" v-validate="'required'"
                                                  name="manufacture">
                                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                                        </v-select>
                                    </div>
                                    <div class="vx-col md:w-1/3 w-full">
                                        <label class="vs-input--label">{{$t('root.streamFormat')}}</label>
                                        <v-select :disabled="isUpdate" v-model="streamFormat" :clearable="false"
                                                  :options="streamFormatOptions" v-validate="'required'"
                                                  name="stream_format">
                                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                                        </v-select>
                                        <span class="text-danger text-sm">{{ errors.first('stream_format') }}</span>
                                    </div>
                                    <div class="vx-col md:w-1/3 w-full">
                                        <label class="vs-input--label">AI Service</label>
                                        <v-select class=""
                                                  v-model="aiServiceModel"
                                                  :clearable="false"
                                                  :options="aiServiceOptions"
                                                  data-vv-validate-on="blur">
                                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col md:w-2/3 w-full">
                                        <vs-input :disabled="isUpdate" class="w-full mb-4" label="Link stream"
                                                  v-model="cameraInfo.streamUrl" v-validate="'required'"
                                                  name="stream_url"/>
                                        <span class="text-danger text-sm">{{ errors.first('stream_url') }}</span>
                                    </div>
                                </div>
                                <!-- Save & Reset Button -->
                                <div class="vx-row">
                                    <div class="vx-col w-full">
                                        <div class="mt-8 flex flex-wrap items-center justify-end">
                                            <vs-button class="ml-auto mt-2" @click="save_changes"
                                                       :disabled="!validateForm">{{ isUpdate ? this.$t('button.update') : this.$t('button.create') }}
                                            </vs-button>
                                            <vs-button class="ml-4 mt-2" type="border" color="warning"
                                                       @click="organizationList">{{$t('button.back')}}
                                            </vs-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vs-tab>
                </vs-tabs>
            </div>
        </vx-card>
    </div>
</template>

<script>

import vSelect from 'vue-select'

export default {
    components: {
        vSelect
    },
    data() {
        return {
            user_data: null,
            activeTab: 0,
            cameraInfo: {
                aiServiceId: null,
                organizationId: null,
                streamFormat: null,
                manufacturer: null,
            },
            branchOptions: [],
            aiServiceOptions: [],
            isUpdate: false,
            manufactureOptions: [
                {label: this.$t('root.null'), value: ""},
                {label: "Hikvision", value: "Hikvision"},
                {label: "Dahua", value: "Dahua"},
                {label: "I-VISION", value: "I-VISION"},
                {label: "Sunell", value: "Sunell"},
                {label: "Qihan", value: "Qihan"},
                {label: "GOLBONG", value: "GOLBONG"},
            ],
            streamFormatOptions: [
                {label: this.$t('root.null'), value: 0},
                {label: "MJPEG", value: 1},
                {label: "H264", value: 2}
            ],
        }
    },
    created() {
        this.$oauth.post('/organization/admin/all').then((response) => {
            response.data.streamFormat = parseFloat(response.data);
            this.branchOptions = response.data.map(x => {
                return {label: x.name, value: x.id}
            });
        });
        this.$crm.get('/ai-service/find-all').then((response) => {
            this.aiServiceOptions = response.data.map(x => {
                return {label: x.name, value: x.id}
            });
            this.aiServiceOptions.unshift({value: null, label: this.$t('root.all')})
        });

        this.cameraInfo.aiServiceId = null;
        this.cameraInfo.organizationId = this.$route.query.organization_id;
        let id = this.$route.query.id;
        if (id) {
            this.isUpdate = true;
            this.$vs.loading();
            this.$crm.post('/camera/admin/find-by-id/' + this.cameraInfo.organizationId + "/" + id).then((response) => {
                response.data.streamFormat = parseFloat(response.data.streamFormat);
                response.data.cameraStatus = response.data.cameraStatus == 1 ? true : false;
                response.data.aiServiceId = response.data.aiService ? response.data.aiService.id : null;
                this.cameraInfo = response.data;
            }).catch((err) => {
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }).finally(() => {
                this.$vs.loading.close();
            })
        }
    },
    computed: {
        streamFormat: {
            get() {
                return {
                    label: this.getLabelString(this.streamFormatOptions.find(x => x.value == this.cameraInfo.streamFormat)),
                    value: this.cameraInfo.streamFormat
                }
            },
            set(obj) {
                this.cameraInfo.streamFormat = obj.value
            }
        },
        manufacture: {
            get() {
                return {
                    label: this.getLabelString(this.manufactureOptions.find(x => x.value == this.cameraInfo.manufacturer)),
                    value: this.cameraInfo.manufacturer
                }
            },
            set(obj) {
                this.cameraInfo.manufacturer = obj.value
            }
        },
        branchModel: {
            get() {
                return {
                    label: this.getLabelString(this.branchOptions.find(x => x.value == this.cameraInfo.organizationId)),
                    value: this.cameraInfo.organizationId
                }
            },
            set(obj) {
                this.cameraInfo.organizationId = obj.value
            }
        },
        aiServiceModel: {
            get() {
                return {
                    label: this.getLabelString(this.aiServiceOptions.find(x => x.value == this.cameraInfo.aiServiceId)),
                    value: this.cameraInfo.aiServiceId
                }
            },
            set(obj) {
                this.cameraInfo.aiServiceId = obj.value
            }
        },
        validateForm() {
            return !this.errors.any()
        }
    },
    methods: {
        getLabelString(object) {
            return (object && object.label) || this.$t('root.null');
        },
        async save_changes(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;

                const result = await this.$validator.validateAll();
                if (!result) return;

                this.cameraInfo.aliasId = "aliasId";
                this.cameraInfo.cameraStatus = this.cameraInfo.cameraStatus ? this.cameraInfo.cameraStatus = 1 : this.cameraInfo.cameraStatus = 0;

                let url = this.isUpdate ? `/camera/admin/update/${this.cameraInfo.organizationId}/${this.cameraInfo.id}` :
                    `/camera/admin/create/${this.cameraInfo.organizationId}/${this.cameraInfo.id}`,
                    data = this.isUpdate ? this.cameraInfo : [this.cameraInfo];

                this.$vs.loading();
                this.$crm.post(url, data).then(() => {
                    this.$vs.notify({
                        color: 'success',
                        text: `${this.isUpdate ? this.$t('button.update') : this.$t('button.create')} ${this.$t('root.cameraSuccess')}`,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                    this.$vs.loading.close();
                    this.$router.push(`/admin/manage-camera`);
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } catch (ex) {
                console.log(ex);
            }
        },
        organizationList() {
            this.$router.push('/admin/manage-camera').catch(() => {
            })
        }
    },
}
</script>

<style scoped>

</style>
